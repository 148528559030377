/* @flow */

import React from "react";
import { useHistory } from "react-router";
import { useTranslate } from "@awardit/react-use-translate";
import { Button } from "@crossroads/ui-components";
import CloseIcon from "icons/cross.svg";

type Props = {
  className?: string,
  onOverview?: boolean,
};

const BackButtons = ({ className, onOverview = false }: Props): React$Node => {
  const t = useTranslate();
  const history = useHistory();

  return (
    <div className={className}>
      {onOverview ?
        <>
          <Button
            onClick={() => history.push("/")}
          >
            <CloseIcon />
          </Button>
          <Button
            className="link"
            onClick={() => history.push("/checkout")}
          >
            {t("CHECKOUT.BACK_TO_CART")}
          </Button>
        </> :
        <Button
          className="link"
          onClick={() => history.push("/")}
        >
          {t("CHECKOUT.CONTINUE_SHOPPING")}
        </Button>
      }
    </div>
  );
};

export default BackButtons;
