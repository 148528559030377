/* @flow */

import React, { useState, useRef, useEffect, useCallback, useContext } from "react";
import cn from "classnames";

import { useData, useSendMessage } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import { QuoteData } from "data";
import Cookies from "js-cookie";
import Button from "components/Button";
import Wrapper from "components/Wrapper";
import QtyPicker from "components/QtyPicker";
import FreeShippingMeter from "components/FreeShippingMeter";
import useFormat from "helpers/use-format";
import useClickOutside from "helpers/use-click-outside";
import { removeQuoteItem, updateQuoteItemQty } from "@crossroads/shop-state/quote";
import { setQuoteDiscountCode } from "state/quote";
import ChevronIcon from "icons/chevron.svg";
import usePrevious from "helpers/use-previous";
import { AnalyticsContext } from "@crossroads/analytics";

import styles from "./styles.scss";
import type { ProductCategory } from "shop-state/types";
type Props = {
  className?: string,
};

type MinimalProduct = {
  +sku: string,
  +name: string,
  +price: { incVat: number, exVat: number, vat: number },
  +attributes: {
    +manufacturer: string,
  },
  +categories: Array<ProductCategory>,
};

const Drawer = ({ open, setOpen }: { open: boolean, setOpen: boolean => void }) => {
  const ref = useRef(null);
  const t = useTranslate();
  const { formatPrice } = useFormat();
  const sendMessage = useSendMessage();
  const quoteData = useData(QuoteData);
  const gaContext = useContext(AnalyticsContext);

  useClickOutside(ref, () => {
    if (open) {
      setOpen(false);
    }
  });

  const sendGAEvent = useCallback((
    product: MinimalProduct,
    quantity: number,
    type: "add_to_cart" | "remove_from_cart") => {
    gaContext.registerModifyCart({
      sku: product.sku,
      name: product.name,
      price: product.price,
      qty: quantity,
      attributes: {
        manufacturer: product.attributes.manufacturer,
      },
      categories: product.categories,
    }, type, product.price.incVat);
  }, [gaContext]);

  const onKeyDown = useCallback(e => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  }, [setOpen]);

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  });

  if (quoteData.state === "LOADING") {
    return null;
  }

  if (!quoteData.data) {
    return null;
  }

  const quote = quoteData.data;
  const processingItem = typeof quoteData.processingItem !== "undefined" ? quoteData.processingItem : null;

  if (!open) {
    return null;
  }

  return (
    <div ref={ref} className={styles.drawer}>
      <Wrapper>
        <h4>{t("CART.MINI_HEADER")}</h4>
        <div className={styles.drawerInner}>
          <table className={styles.items}>
            <tbody>
              {quote.items.map(x => {
                const product = x.configOption ? {
                  ...x.product,
                  ...x.configOption.product,
                } : x.product;

                return (
                  <tr
                    key={x.itemBuyRequest}
                    className={cn(
                      styles.item,
                      { [styles.processing]: processingItem === x.itemBuyRequest }
                    )}
                  >
                    <td className={styles.itemName}>
                      {product.name}
                    </td>
                    <td className={styles.itemQty}>
                      <QtyPicker
                        size="small"
                        value={x.qty}
                        setValue={qty => {
                          const type = qty > x.qty ? "add_to_cart" : "remove_from_cart";
                          if (qty < 1) {
                            sendMessage(removeQuoteItem(x.itemBuyRequest));
                            sendGAEvent(product, 1, type);
                          }
                          else {
                            sendMessage(updateQuoteItemQty(x.itemBuyRequest, qty));
                            sendGAEvent(product, Math.abs(qty - x.qty), type);
                          }
                        }}
                      />
                    </td>
                    <td className={styles.itemRemove}>
                      <Button
                        variant="link"
                        onClick={() => sendMessage(removeQuoteItem(x.itemBuyRequest))}
                      >
                        {t("CART.REMOVE_PRODUCT_SHORT")}
                      </Button>
                    </td>
                    <td className={styles.itemTotals}>
                      {formatPrice((x.qty * x.product.price.incVat) || 0)}
                    </td>
                  </tr>
                );
              })}
              {quote.discountTotal < -0.01 &&
              <tr>
                <td>{t("SUMMARY.DISCOUNT")}</td>
                <td colSpan="3">
                  {formatPrice(quote.discountTotal)}
                </td>
              </tr>
              }
            </tbody>
          </table>

          <div className={styles.totals}>
            <strong>{t("CART.SUBTOTAL")}</strong>
            {formatPrice(quote.grandTotal.incVat)}
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

const CartMini = ({ className }: Props): React$Node => {
  const t = useTranslate();
  const { formatPrice } = useFormat();
  const [open, setOpen] = useState(false);
  const quoteData = useData(QuoteData);
  const sendMessage = useSendMessage();
  const previousState = usePrevious(quoteData.state);

  useEffect(() => {
    if (quoteData.state !== "ADDING_ITEM" && previousState === "ADDING_ITEM") {
      setOpen(true);
    }

    const coupon = Cookies.get("coupon");
    if (quoteData.state !== "LOADING" && quoteData.data && quoteData.data.items.length > 0 && !(quoteData.data.coupon) && coupon !== undefined) {
      sendMessage(setQuoteDiscountCode(coupon));
    }
  }, [quoteData.state]);

  if (quoteData.state === "LOADING") {
    return null;
  }

  const quote = quoteData.data;

  if (!quote || quote.items.length === 0) {
    return null;
  }

  return (
    <div className={cn(styles.block, { [styles.open]: open }, className)}>
      <Drawer open={open} setOpen={setOpen} />

      <div className={styles.cart}>
        <Wrapper className={styles.cart__wrapper}>
          <div className={styles.cart__left}>
            <Button
              className={styles.toggleButton}
              onClick={() => open ? setOpen(false) : setOpen(true)}
            >
              <ChevronIcon />
              {open ? t("CART.MINI_SHOW_LESS") : t("CART.MINI_SHOW_MORE")}
            </Button>
            <div className={styles.subTotal}>
              <span>{t("CART.SUBTOTAL")}:</span>
              <span>{formatPrice(quote.grandTotal.incVat)}</span>
            </div>

            {!quote.isVirtual &&
              <FreeShippingMeter
                current={quote.subTotal.incVat}
                className={styles.freeShippingMeter}
              />
            }
          </div>
          <div className={styles.cart__right}>
            <Button className={styles.toCheckout} to="/checkout" variant="inverted__accent">
              {t("CART.CTA")}
            </Button>
          </div>
        </Wrapper>
      </div>
    </div>
  );
};

export default CartMini;
