/* @flow */

import React, { useContext, useMemo } from "react";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import Field, { SearchableDropdownField } from "components/Field";

import styles from "./styles.scss";

type Props = {
  type: "billing" | "shipping",
  className?: string,
};

const Address = ({ type, className }: Props): React$Node => {
  const t = useTranslate();
  const { info: { countries, locale } } = useContext(StoreInfoContext);

  const sortedCountries = useMemo(() => {
    return countries.slice().sort((a, b) => {
      return a.name.localeCompare(
        b.name,
        locale.split("_")[0],
        { sensitivity: "base" }
      );
    });
  }, [countries, locale]);

  return (
    <div className={className}>
      <div className={styles.row}>
        <div className={styles.s50}>
          <Field name={`${type}.firstname`} autoComplete="given-name" label={t("ADDRESS.FIRSTNAME")} variant="float" />
        </div>
        <div className={styles.s50}>
          <Field name={`${type}.lastname`} autoComplete="family-name" label={t("ADDRESS.LASTNAME")} variant="float" />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.s50}>
          <Field name={`${type}.street.0`} autoComplete="address-line1" label={t("ADDRESS.STREET_1")} variant="float" />
        </div>
        <div className={styles.s50}>
          <Field name={`${type}.street.1`} autoComplete="address-line2" label={t("ADDRESS.STREET_2")} variant="float" />
        </div>
      </div>

      <div className={styles.row}>
        <div className={sortedCountries.length > 1 ? styles.s33 : styles.s50}>
          <Field name={`${type}.postcode`} autoComplete="postal-code" label={t("ADDRESS.POSTCODE")} variant="float" />
        </div>
        <div className={sortedCountries.length > 1 ? styles.s33 : styles.s50}>
          <Field name={`${type}.city`} autoComplete="address-level2" label={t("ADDRESS.CITY")} variant="float" />
        </div>
        {sortedCountries.length > 1 &&
          <div className={styles.s33}>
            <SearchableDropdownField
              variant="countryDropdown"
              label={t("ADDRESS.COUNTRY")}
              autoComplete={`${type} country`}
              className={styles.countries}
              name={`${type}.countryCode`}
              placeholder=""
              items={sortedCountries} />
          </div>
        }
      </div>
      <div className={styles.row}>
        {type === "billing" &&
          <div className={styles.s50}>
            <Field name="email" autoComplete="email" label={t("ADDRESS.EMAIL")} variant="float" />
          </div>
        }
        <div className={styles.s50}>
          <Field name={`${type}.telephone`} autoComplete="tel" label={t("ADDRESS.TELEPHONE")} variant="float" />
        </div>
      </div>
    </div>
  );
};

export default Address;
