/* @flow */

import React from "react";
import cn from "classnames";
import styles from "./styles.scss";
import { useTranslate } from "@awardit/react-use-translate";

import PlusIcon from "icons/plus.svg";
import MinusIcon from "icons/minus.svg";

type Props = {
  value: number,
  setValue: number => void,
  min?: number,
  max?: number,
  className?: string,
  size?: "small" | "large",
};

const QtyPicker = ({
  value,
  setValue,
  min = 0,
  max = 99,
  size = "large",
  className }: Props): React$Node => {
  const t = useTranslate();

  const setQty = (qty: number) => {
    setValue(Math.min(max, Math.max(qty, min)));
  };

  return (
    <div className={cn(styles.block, styles[`size__${size}`], className)}>
      <button
        type="button"
        aria-label={t("CART.DECREMENT")}
        className={styles.decrement}
        disabled={value <= min}
        onClick={() => setQty(value - 1)}
      >
        <MinusIcon />
      </button>

      <span>{value} {t("CART.QTY")}</span>

      <button
        type="button"
        aria-label={t("CART.INCREMENT")}
        className={styles.increment}
        disabled={value >= max}
        onClick={() => setQty(value + 1)}
      >
        <PlusIcon />
      </button>
    </div>
  );
};

export default QtyPicker;
