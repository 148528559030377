/* @flow */

import React, { useState } from "react";
import cn from "classnames";
import { useData } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import { Button, Lightbox } from "@crossroads/ui-components";
import { CmsData } from "data";
import { CheckboxField } from "components/Field";
import CloseIcon from "icons/cross.svg";

import styles from "./styles.scss";

type Props = {
  className?: string,
};

const Terms = ({ className }: Props): React$Node => {
  const t = useTranslate();
  const termsData = useData(CmsData);
  const [showTerms, setShowTerms] = useState(false);

  return (
    <div className={cn(className, styles.block)}>
      {termsData.state === "LOADED" &&
        <Lightbox
          className={styles.lightbox}
          open={showTerms}
          setOpen={() => setShowTerms(false)}
        >
          <header className={styles.header}>
            <h3 className={styles.heading}>
              {termsData.data.contentHeading}
            </h3>

            <Button
              className={styles.closeButton}
              onClick={() => setShowTerms(false)}
            >
              <CloseIcon />
            </Button>
          </header>
          <div className={styles.container}>
            <article
              dangerouslySetInnerHTML={{ __html: termsData.data.content }}
              tabIndex="-1"
              className={cn(
                styles.body,
                "typography"
              )} />
          </div>
        </Lightbox>
      }

      <div className={styles.terms}>
        <CheckboxField
          id="terms"
          name="terms"
        >
          <span>{t("CHECKOUT.I_ACCEPT_THE")}{" "}</span>
          <span
            className={cn({ [styles.link]: termsData.state === "LOADED" })}
            onClick={e => {
              e.preventDefault();
              setShowTerms(true);
            }}
          >
            {t("CHECKOUT.TERMS")}
          </span>
        </CheckboxField>
      </div>
    </div>
  );
};

export default Terms;
