/* @flow */

import type { Step } from "helpers/use-checkout-steps";

import React, { useEffect, useContext } from "react";
import { Route, Switch } from "react-router";
import { useTranslate } from "@awardit/react-use-translate";
import { useData } from "crustate/react";
import { QuoteData } from "data";
import { getQuoteData } from "state/quote";
import { Helmet } from "react-helmet-async";
import useCheckoutSteps from "helpers/use-checkout-steps";
import { AnalyticsContext } from "@crossroads/analytics";

import BackButtons from "components/CheckoutView/BackButtons";
import Cart from "components/CheckoutView/Cart";
import Overview from "components/CheckoutView/Overview";
import Wrapper from "components/Wrapper";

import styles from "./styles.scss";

type CheckoutHeaderProps = {
  empty?: boolean,
  step?: ?Step,
};

const CheckoutHeader = ({ empty, step }: CheckoutHeaderProps) => {
  const t = useTranslate();

  if (empty === true || !step) {
    return (
      <header className={styles.header}>
        <h1 className={styles.heading}>{t("CART.EMPTY")}</h1>
        <BackButtons className={styles.backButtons} />
      </header>
    );
  }

  return (
    <header className={styles.header}>
      <h1 className={styles.heading}>
        {step ? step.titleLong : t("CHECKOUT.STEPS.CART.LONG")}
      </h1>
      <BackButtons
        className={styles.backButtons}
        onOverview={step.id === "overview"}
      />
    </header>
  );
};

const CheckoutView = (): React$Node => {
  const t = useTranslate();
  const quoteData = useData(QuoteData);
  const quote = getQuoteData(useData(QuoteData));
  const gaContext = useContext(AnalyticsContext);
  const { currentStep } = useCheckoutSteps();

  useEffect(() => {
    if (!currentStep) {
      return;
    }

    if (currentStep.key === 2) {
      if (quote) {
        gaContext.registerBeginCheckoutProcess(
          quote.items,
          quote.grandTotal.incVat
        );
      }
    }

    gaContext.registerCheckoutStep(
      currentStep.key,
      currentStep.id
    );
  }, [currentStep]);

  if (quoteData.state === "LOADING" || !quote) {
    return null;
  }

  if (quoteData.state === "LOADED" && quoteData.data.items.length === 0) {
    return (
      <Wrapper className={styles.block}>
        <Helmet title={t("CHECKOUT.TITLE")} />
        <CheckoutHeader empty />
      </Wrapper>
    );
  }

  return (
    <Wrapper className={styles.block}>
      <Helmet title={t("CHECKOUT.TITLE")} />
      <CheckoutHeader step={currentStep} />
      <Switch>
        <Route exact path="/checkout/overview" render={() => <Overview quote={quote} />} />
        <Route exact path="/checkout" component={Cart} />
      </Switch>
    </Wrapper>
  );
};

export default CheckoutView;
