/* @flow */

import React, { memo, useContext } from "react";
import { useSendMessage } from "crustate/react";
import { syncQuote } from "@crossroads/shop-state/quote";
import { addMessage } from "@crossroads/shop-state/messages";
import { useTranslate } from "@awardit/react-use-translate";
import {
  makeCollectorCheckoutView,
  makeCollectorCheckoutProvider } from "@crossroads/shop-views";
import LoadingView from "components/LoadingView";
import { QuoteData } from "data";
import { useClient, StoreInfoContext } from "entrypoint/shared";
import { quote as quoteQuery } from "queries";
import PurchaseLimitMessage from "components/CheckoutView/PurchaseLimitMessage";

import { collector } from "config.js";
import styles from "./styles.scss";

const View = makeCollectorCheckoutView({ QuoteData, LoadingView });
const Provider = makeCollectorCheckoutProvider({
  collectorScriptSrc: collector || "",
  textColor: styles.textColor,
  primaryColor: styles.primaryColor,
  useClient,
  DataVersion: "v1",
});

const CollectorCheckout: React$AbstractComponent<any, any> = memo(() => {
  const t = useTranslate();
  const client = useClient();
  const sendMessage = useSendMessage();
  const { info: { baseUrl } } = useContext(StoreInfoContext);
  const successUri = baseUrl + "checkout/success";
  const cancelUri = baseUrl + "checkout";

  const syncQ = async () => {
    const { quote } = await client(quoteQuery);

    if (quote) {
      sendMessage(syncQuote(quote));
    }
  };

  const onPollError = () => {
    sendMessage(addMessage("COLLECTOR_CHECKOUT.POLL_ERROR", "error"));
  };

  const onSessionExpired = () => {
    sendMessage(addMessage("COLLECTOR_CHECKOUT.SESSION_EXPIRED", "error"));
  };

  return (
    <Provider
      ietfLang={t("LOCALE.ISO.IETF")}
      syncQuote={syncQ}
      onPollError={onPollError}
      onSessionExpired={onSessionExpired}
    >
      <PurchaseLimitMessage />
      <View className={styles.view} successUri={successUri} cancelUri={cancelUri} />
    </Provider>
  );
});

export default CollectorCheckout;
