/* @flow */

import React, { useEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "@crossroads/analytics";
import { useTranslate } from "@awardit/react-use-translate";
import Wrapper from "components/Wrapper";
import LoadingView from "components/LoadingView";
import { OrderData } from "data";
import { useData } from "crustate/react";
import { useClient } from "entrypoint/shared";
import { subscribeToNewsletter } from "queries";
import Cookies from "js-cookie";

import NotFoundView from "components/NotFoundView";

import styles from "./styles.scss";

const SuccessView = (): React$Node => {
  const t = useTranslate();
  const gaContext = useContext(AnalyticsContext);
  const orderData = useData(OrderData);
  const client = useClient();

  useEffect(() => {
    if (orderData.state === "LOADED" && orderData.data) {
      gaContext.registerCheckoutSuccess(
        orderData.data,
        orderData.data.grandTotal.incVat
      );
    }
  }, [orderData, gaContext]);

  useEffect(() => {
    const userSubscribed = JSON.parse(Cookies.get("opt-email") || "");
    const subscribeToNewsletterCallback = async () => {
      if (orderData.state === "LOADED" &&
      orderData.data &&
      orderData.data.email
      ) {
        try {
          await client(subscribeToNewsletter, { email: orderData.data.email });
        }
        catch (e) {
          console.log("Could not subcribe to newsletter", e);
        }
      }
    };

    if (userSubscribed) {
      subscribeToNewsletterCallback();
    }
  }, [orderData, client]);

  if (orderData.state !== "LOADED") {
    return <LoadingView text={t("SUCCESS.LOADING")} />;
  }

  if (!orderData.data) {
    return (
      <Wrapper>
        <NotFoundView />
      </Wrapper>
    );
  }

  const { email, id } = orderData.data;

  return (
    <Wrapper className={styles.success} variant="small">
      <Helmet title={t("SUCCESS.TITLE")} />
      <h1>
        {t("SUCCESS.HEADING")}
      </h1>

      <div className={styles.separatorTop} />

      <h6 className={styles.orderIdTitle}>
        {t("SUCCESS.ORDER_ID")}
      </h6>

      <p className={styles.orderIdId}>
        {id}
      </p>

      <div className={styles.separatorBottom} />

      <p>
        {t("SUCCESS.CONFIRMATION", { email })}
      </p>

      <p>
        {t("SUCCESS.DELIVERY")}
      </p>
    </Wrapper>
  );
};

export default SuccessView;
