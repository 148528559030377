/* @flow */

import React, { useContext } from "react";
import { useData } from "crustate/react";
import { HomeData, AdventCalendarData } from "data";
import { StoreInfoContext } from "entrypoint/shared";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import { formatMeta } from "helpers/get-meta";
import Wrapper from "components/Wrapper";
import LoadingView from "components/LoadingView";
import Slider from "components/Slider";
import CategoryCards from "./CategoryCards";
import CategoryPreview from "./CategoryPreview";
import AdventCalendar from "components/AdventCalendar";
import { adventCalendarActive } from "state/advent-calendar";

import styles from "./styles.scss";

const enrichImagesData = (data: any) => {
  return data.map(s => ({
    ...s,
    images: [
      { ...s.images.large, media: `screen` },
    ],
  }));
};

const HomeView = (): React$Node => {
  const t = useTranslate();
  const homeData = useData(HomeData);
  const adventCalendarData = useData(AdventCalendarData);
  const location = useLocation();
  const testDate = new URLSearchParams(location.search).get("testDate");
  const {
    info: { defaultTitle, defaultDescription },
    categories,
    date,
  } = useContext(StoreInfoContext);

  const now = testDate ? new Date(testDate) : new Date(date);

  if (homeData.state !== "LOADED") {
    return <LoadingView />;
  }

  const meta = formatMeta({
    title: defaultTitle,
    description: defaultDescription,
  });

  const heroData =
    homeData.data.hero.map(c => ({
      heading: c.block.heading,
      description: c.block.text,
      url: c.block.link,
      images: {
        large: {
          url: c.block.largeImage && c.block.largeImage.x2,
          dataUrl: c.block.largeImage && c.block.largeImage.thumb,
        },
      },
      button: {
        text: c.block.cta,
        link: c.block.link,
      },
    }));

  return (
    <div className={styles.block}>
      <Helmet
        title={meta.title}
        meta={meta.data}
      />

      {adventCalendarActive(now) ? (
        adventCalendarData.state === "LOADED" && (
          <AdventCalendar doors={adventCalendarData.data} holidayType="easter" />
        )) :
        (
          <Wrapper className={styles.topWrapper}>
            <section className={styles.slider}>
              <Slider slides={enrichImagesData(heroData)} />
            </section>
          </Wrapper>
        )}

      <Wrapper className={styles.topWrapper}>
        <div className={styles.intro}>
          <p>
            {t("HOMEVIEW.INTRO")}
          </p>
          <p>
            {t("HOMEVIEW.INTRO_2")}
          </p>
        </div>
      </Wrapper>

      <Wrapper className={styles.categories}>
        <CategoryCards categories={categories} />
      </Wrapper>

      <Wrapper className={styles.previews}>
        {homeData.data.popularProductsPreview &&
          <CategoryPreview
            className={styles.row}
            direction="left"
            category={homeData.data.popularProductsPreview}
          />
        }
        {homeData.data.categoryPreview1 &&
          <CategoryPreview
            className={styles.row}
            direction="right"
            category={homeData.data.categoryPreview1}
          />
        }
        {homeData.data.categoryPreview2 &&
          <CategoryPreview
            className={styles.row}
            direction="left"
            category={homeData.data.categoryPreview2}
          />
        }
      </Wrapper>
    </div>
  );
};

export default HomeView;
