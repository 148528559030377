/* @flow */

import type { ProductCategory, QuoteItem } from "shop-state/types";

import React, { useState, useContext, useEffect, useCallback } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { AnalyticsContext } from "@crossroads/analytics";
import { useTranslate } from "@awardit/react-use-translate";
import { removeQuoteItem, updateQuoteItemQty } from "@crossroads/shop-state/quote";
import { Dialogue, Checkbox } from "@crossroads/ui-components";
import { useSendMessage, useData } from "crustate/react";
import Cookies from "js-cookie";

import Button from "components/Button";
import QtyPicker from "components/QtyPicker";
import { QuoteData } from "data";
import useFormat from "helpers/use-format";
import CloseIcon from "icons/close-small.svg";

import CartSummary from "components/CheckoutView/CartSummary";
import Container from "components/CheckoutView/Container";
import CustomerServiceLink from "components/CheckoutView/CustomerServiceLink";
import DiscountCode from "./DiscountCode";
import { Box, BoxHeader } from "components/Box";
import CheckboxIcon from "icons/checkbox.svg";
import PurchaseLimitMessage from "components/CheckoutView/PurchaseLimitMessage";

import styles from "./styles.scss";

type MinimalProduct = {
  +sku: string,
  +name: string,
  +price: { incVat: number, exVat: number, vat: number },
  +attributes: {
    +manufacturer: string,
  },
  +categories: Array<ProductCategory>,
};

const QUOTE_ITEM_UPDATING_STATES = new Set([
  "UPDATING_ITEM",
  "REMOVING_ITEM",
]);

const Cart = (): React$Node => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const gaContext = useContext(AnalyticsContext);
  const quote = useData(QuoteData);
  const [open, setOpen] = useState(true);
  const [activeItem, setActiveItem] = useState<QuoteItem | null>(null);
  const [confirmDialogueOpen, setConfirmDialogueOpen] = useState(false);
  const [updatingQuote, setUpdatingQuote] = useState<boolean>(false);
  const [receiveOffers, setReceiveOffers] = useState<boolean>(false);

  useEffect(() => {
    if (QUOTE_ITEM_UPDATING_STATES.has(quote.state)) {
      setUpdatingQuote(true);
    }
    else {
      setUpdatingQuote(false);
    }
  }, [quote.state]);

  useEffect(() => {
    Cookies.set("opt-email", receiveOffers.toString());
  }, [receiveOffers]);

  useEffect(() => {
    if (quote.data) {
      gaContext.viewedCart(quote.data.grandTotal.incVat, quote.data.items.map(item => {
        return {
          sku: item.sku ?? item.product.sku,
          name: item.product.name,
          price: {
            exVat: item.rowTotal.exVat,
            incVat: item.rowTotal.incVat,
            vat: item.rowTotal.incVat - item.rowTotal.exVat,
          },
          qty: item.qty,
          attributes: {
            manufacturer: item.product.attributes.manufacturer,
          },
          categories: item.product.categories,
        };
      }));
    }
  }, []);

  const sendGAEvent = useCallback((
    product: MinimalProduct,
    quantity: number,
    type: "add_to_cart" | "remove_from_cart") => {
    gaContext.registerModifyCart({
      sku: product.sku,
      name: product.name,
      price: product.price,
      qty: quantity,
      attributes: {
        manufacturer: product.attributes.manufacturer,
      },
      categories: product.categories,
    }, type, product.price.incVat);
  }, [gaContext]);

  if (quote.state === "LOADING") {
    return null;
  }

  const remove = (item: QuoteItem) => {
    sendGAEvent(item.product, 1, "remove_from_cart");
    sendMessage(removeQuoteItem(item.itemBuyRequest));
  };

  const setQty = (v: number, product: MinimalProduct, item: QuoteItem): void => {
    const type = v > item.qty ? "add_to_cart" : "remove_from_cart";

    if (v > 0) {
      sendMessage(updateQuoteItemQty(item.itemBuyRequest, v));
      sendGAEvent(product, Math.abs(v - item.qty), type);
    }
    else {
      setConfirmDialogueOpen(true);
      setActiveItem(item);
    }
  };

  const processingItem = typeof quote.processingItem !== "undefined" ? quote.processingItem : null;

  if (!quote.data) {
    return null;
  }

  const { items, coupon } = quote.data;

  return (
    <Container
      right={
        <div>
          <CartSummary open={open} setOpen={setOpen}>
            <div className={styles.submitButtonContainer}>
              <Button
                className={styles.submitButton}
                variant="primary"
                to="/checkout/overview"
              >
                {t("CART.TO_CHECKOUT")}
              </Button>
            </div>
          </CartSummary>

          <CustomerServiceLink />
        </div>
      }
    >
      {confirmDialogueOpen &&
        <Dialogue
          className={styles.confirmDialogue}
          open={confirmDialogueOpen}
          setOpen={() => setConfirmDialogueOpen(false)}
          title={t("CART.REMOVE_PRODUCT_LONG")}
          closeIcon={<div className={styles.closeIconWrapper}><CloseIcon /></div>}
          primaryAction={t("CART.REMOVE_APPROVE")}
          secondaryAction={t("CONFIRM_DIALOG.CANCEL")}
          onPrimaryAction={() => {
            if (activeItem) {
              setConfirmDialogueOpen(false);
              remove(activeItem);
            }
          }}
          onSecondaryAction={() => setConfirmDialogueOpen(false)}
        >
          {t("CART.CONFIRM_TEXT", {
            itemName: `${activeItem?.product.name || ""}, ${activeItem?.product.attributes.manufacturer || ""}`,
          })}
        </Dialogue>
      }
      <div className={styles.block}>
        {items.map(x => {
          return (
            <CartItem
              key={x.itemBuyRequest}
              item={x}
              disabled={quote.state === "UPDATING_ITEM"}
              setQty={setQty}
              processing={x.itemBuyRequest === processingItem}
            />
          );
        })}

        <DiscountCode
          disabled={updatingQuote}
          coupon={coupon}
          setSummaryOpen={setOpen}
        />
        <Box className={styles.block}>
          <BoxHeader className={styles.boxHeader}>
            <Checkbox
              name="opt-out"
              value={receiveOffers}
              defaultChecked={receiveOffers}
              className={styles.checkbox}
              CheckedComponent={CheckboxIcon}
              onChange={e => setReceiveOffers(e.target.checked)}
            >
              {t("CART.SUBSCRIBE_TO_NEWSLETTER")}
            </Checkbox>
          </BoxHeader>
        </Box>
        <PurchaseLimitMessage />
      </div>
    </Container>
  );
};

const CartItem = ({
  item,
  disabled,
  setQty,
  processing,
}: {
  item: QuoteItem,
  disabled: boolean,
  setQty: (v: number, product: MinimalProduct, item: QuoteItem) => void,
  processing: boolean }
) => {
  const { formatPrice } = useFormat();

  const product = item.configOption ? {
    ...item.product,
    ...item.configOption.product,
    categories: item.product.categories,
  } : item.product;

  return (
    <div
      key={item.itemBuyRequest}
      className={
        cn(styles.item, {
          [styles.processing]: processing,
          [styles.disabled]: disabled,
        })}
    >
      <div className={styles.itemInner}>
        <div className={styles.left}>
          <img
            className={styles.image}
            alt={product.name}
            src={product.attributes.image?.x1}
          />
          <div className={styles.info}>
            <Link
              to={{
                pathname: product.url,
                state: { hint: {
                  type: "product",
                  product,
                  image: product.attributes.image?.x1,
                } },
              }}
              className={styles.name}
            >
              {product.name}
            </Link>

            <p className={styles.manufacturer}>
              {product.attributes.manufacturer}
            </p>

            {item.bundleOptions &&
              <ul className={styles.bundleOptions}>
                {item.bundleOptions.map(o => o.products.map(p =>
                  <li key={o.title}>{p.product.name}</li>
                ))}
              </ul>
            }
          </div>
        </div>

        <div className={styles.right}>
          <QtyPicker
            className={styles.qtyPicker}
            value={item.qty}
            min={0}
            setValue={v => setQty(v, product, item)} />
          <div className={styles.priceWrapper}>
            <p>{formatPrice((item.qty * item.product.price.incVat) || 0)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
